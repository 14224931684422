import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { IProject, TagEntity } from '../../interfaces/IProject';
import './Card.scss';

const Card = ({ item }: { item: IProject }) => {
  return (
    <>
      <Link to={item.node.slug} className="card shadow-box ">
        <div className="card-header-custom">
          <Img fluid={item.node.imgUrl.childImageSharp.fluid} />
        </div>
        <div className="card-body-custom">
          <h4>{item.node.title}</h4>
          <p>{item.node.description}</p>
          <div>
            {item.node.tag?.map((tag: TagEntity) => (
              <span
                className={`badge rounded-pill ${tag.class} me-1`}
                key={tag.name}
              >
                {tag.name}
              </span>
            ))}
          </div>
          <div className="user">
            <div className="user-info">
              <h5>{item.node.date}</h5>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Card;
