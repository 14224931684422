import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import React, { useState } from 'react';
import { NavItem } from 'react-bootstrap';
import Card from '../../components/card/Card';
import Layout from '../../components/Layout';
import { IProject } from '../../interfaces/IProject';
import './Projects.scss';

export const query = graphql`
  query allProjectsJson {
    allProjectsJson {
      edges {
        node {
          slug
          tag {
            id
            name
            class
          }
          title
          id
          description
          date
          layout
          imgUrl {
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

const Projects = ({ data }: any) => {
  const projectsData = data.allProjectsJson.edges;

  const [stateProjects, setProjects] = useState({
    tag: '0',
    data: projectsData,
  });

  const handleChange = (e: { target: { value: string } }) => {
    const tag = e.target.value;
    let filteredProjects = [];
    if (tag == '0') {
      filteredProjects = projectsData;
    } else {
      filteredProjects = _.filter(projectsData, {
        node: { tag: [{ id: parseInt(tag, 10) }] },
      });
    }

    setProjects({
      tag: '0',
      data: filteredProjects,
    });
  };

  return (
    <Layout>
      <div className="container">
        <div className="d-flex justify-content-center mb-3">
          <h1 className="text-center titleEffect">
            <i className="bi bi-briefcase-fill"></i> The work what I do
          </h1>
        </div>
        <select
          name=""
          id=""
          className="form-control"
          onChange={e => handleChange(e)}
        >
          <option value="0">Select a tag</option>
          <option value="1">HTML</option>
          <option value="2">CSS</option>
          <option value="3">JS</option>
          <option value="5">React</option>
          <option value="6">API</option>
        </select>
        <div className="cards-container">
          {stateProjects.data.map((item: IProject) => (
            <Card item={item} key={item.node.id} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Projects;
